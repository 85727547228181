<template>
  <QTable
    :ref="c => {
      tableScrollEl = (c?.$el as HTMLDivElement)?.querySelector('.q-table__middle');
    }"
    v-bind="$attrs"
    v-model:selected="selectedItems"
    v-model:pagination="pagination"
    :rows="rows"
    :columns="columns"
    :visible-columns="visibleCols"
    :selection="$slots['batch-actions'] ? 'multiple' : 'none'"
    :rows-per-page-options="perPageOptions"
    flat
    :class="tableClasses"
    style="max-width: 100%;"
    @request="pagination = $event.pagination"
  >
    <template #top-row="scope">
      <QTr v-if="error">
        <QTd
          :colspan="scope.cols.length"
          style="padding: 0;"
        >
          <BaseAlert type="error">
            {{ error }}
          </BaseAlert>
        </QTd>
      </QTr>
      <slot
        name="top-row"
        v-bind="scope"
        :search-string="searchString ?? ''"
      />
    </template>
    <template #top>
      <slot
        v-if="selectedItems.length > 0"
        name="batch-actions"
        v-bind="{
          rows: selectedItems,
          refresh,
          clear: clearSelectedItems,
        }"
      />
      <div
        v-else-if="!noToolbar"
        class="full-width row no-wrap items-start q-gutter-x-sm wrap-xs"
        style="height: 60px;"
      >
        <NewFilterButton v-if="availableFilters" />

        <div
          v-if="isEditingFilter"
          class="col row no-wrap items-start"
        >
          <FilterEditor />
        </div>

        <SearchInput
          v-else-if="withSearch"
          v-model="searchString"
          :debounce="debounce"
          :blurred-search="blurredSearch"
          class="col"
        />

        <QSpace />

        <slot
          name="after-search-string"
          :options="options"
          :refresh="refresh"
        />

        <ColumnsSettings
          v-if="!hideColumnSettings"
          v-model:selected-headers="visibleFieldsNames"
          :headers="fields"
          :dependent-headers="dependentFields"
        />
      </div>

      <QToolbar
        v-if="filters.length > 0"
        class="q-px-none"
      >
        <FiltersList />
      </QToolbar>
    </template>

    <template
      v-if="rows.length > 0 && summaryRow"
      #bottom-row="{ cols }"
    >
      <SummaryRow
        :row="summaryRow"
        :cols="cols"
      />
    </template>

    <template
      v-for="[slotName, field] in bodyCellSlots"
      #[slotName]="slotData"
    >
      <slot
        :name="slotName"
        v-bind="slotData"
        :loading="loadingFields.includes(field)"
      >
        <QTd
          :props="slotData"
          :title="slotData.col.title ? getRowField(slotData.row, slotData.col.title) : undefined"
        >
          <QSkeleton v-if="loadingFields.includes(field)" />
          <template v-else>
            {{ slotData.value }}
          </template>
        </QTd>
      </slot>
    </template>

    <template
      v-for="name in tableSlots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>

    <template
      v-for="[slotName, field] in titledHeaders"
      #[slotName]="slotData"
      :key="slotName"
    >
      <QTh
        :props="slotData"
        :title="field.labelTitle"
      >
        {{ field.label }}
      </QTh>
    </template>

    <template #bottom-left>
      <slot
        v-if="'bottom-left' in $slots"
        name="bottom-left"
      />
      <BooleanSelect
        v-else-if="deletionFilter"
        v-model="showDeleted"
        :label="t('Show')"
        :true-text="t('All')"
        :false-text="t('All but deleted')"
        style="width: 200px;"
        borderless
        dense
        options-dense
        options-cover
      />
    </template>
  </QTable>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import ColumnsSettings from '@/components/BaseTable/ColumnsSettings.vue';
import SearchInput from '@/components/BaseTable/SearchInput.vue';
import BooleanSelect from '@/components/BooleanSelect.vue';
import useReportFilters from '@/composables/useReportFilters';
import type { ReportFilterInput } from '@/graphql/types';
import { SortOrderEnum } from '@/graphql/types';
import type {
  DependentFieldsMap,
  Filter,
  ReportFilter,
  ReportOptions,
  RowsFilter,
  TableColumn,
} from '@/types/reports';
import { StorageSerializers, useLocalStorage, useScroll, watchOnce } from '@vueuse/core';
import { type QTableProps, QTd, QTr } from 'quasar';
import { difference, isNil, reject, sortBy } from 'ramda';
import { type Component, computed, h, nextTick, ref, useSlots, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();

const router = useRouter();
const route = useRoute();

type PersistentOptions = {
  searchString: string | null;
  filters: Filter[];
  headers: string[];
  scroll: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  showDeleted: boolean;
};

type TItem = Record<string, unknown>;

const perPageOptions = [10, 20, 50, 100];

const slots = useSlots();

const props = withDefaults(defineProps<{
  rows: TItem[];
  withSearch?: boolean;
  rowIsDeleted?: (item: TItem) => boolean;
  noToolbar?: boolean;
  debounce?: number;
  fields: TableColumn<TItem>[];
  availableFilters?: ReportFilter[];
  fixedFilters?: ReportFilterInput[];
  dependentFields?: DependentFieldsMap<TItem>;
  storagePrefix?: string;
  keepPageInUrl?: boolean;
  keepSearchString?: boolean;
  total?: number;
  summaryRow?: TItem;
  showLineNumbers?: boolean;
  hideColumnSettings?: boolean;
  deletionFilter?: (showDeleted: boolean) => ReportFilterInput | null;
  stickyHeader?: boolean;
  denseTop?: boolean;
  blurredSearch?: boolean;
  rowsFilter?: RowsFilter<TItem>;
  error?: string;
  noShrink?: boolean;
  noLoadingColumns?: boolean;
}>(), {
  debounce: 500,
  keepSearchString: true,
});

const emit = defineEmits<{
  (e: 'refresh', options: ReportOptions | null): void;
  (e: 'single-item-found', item: TItem, searchString: string | null): void;
}>();

const visibleFieldsNames = ref(props.fields.map(h => h.name));

const selectedItems = ref<TItem[]>([]);

const searchString = ref<string | null>('');

const pagination = ref<QTableProps['pagination']>(null!);

const tableScrollEl = ref<HTMLDivElement>();

const { y: scroll } = useScroll(tableScrollEl, { throttle: 50 });

const scrollAfterFirstLoad = ref(0);

// noinspection LocalVariableNamingConventionJS
const {
  isEditing: isEditingFilter,
  filters,
  filtersForInput,
  showDeleted,
  restoreFilters,
  NewFilterButton,
  FilterEditor,
  FiltersList,
} = useReportFilters(props.availableFilters, props.fixedFilters, props.deletionFilter);

const storedOptions = props.storagePrefix ? useLocalStorage<PersistentOptions>(
  props.storagePrefix,
  null,
  { serializer: StorageSerializers.object },
) : null;

const columns = computed<TableColumn[]>(() => sortBy(
  c => visibleCols.value.indexOf(c.name),
  [
    {
      label: '#',
      name:  'index',
      field: item => props.rows.indexOf(item) + 1,
    } as TableColumn,
    {
      name: 'actions',
    } as TableColumn,
    ...props.fields,
    {
      name:  'right-spacer',
      style: 'width: 9999px;'
    } as TableColumn,
  ].map(col => ({
    ...col,
    classes: cellClass,
  })),
));

const visibleCols = computed((): string[] => reject(isNil, [
  props.showLineNumbers ? 'index' : null,
  slots['body-cell-actions'] ? 'actions' : null,
  ...visibleFieldsNames.value,
]));

const bodyCellSlots = computed(() => visibleFieldsNames.value.map(name => [`body-cell-${name}`, name]));

const tableSlots = computed((): string[] =>
  Object.keys(slots).filter(s => s.startsWith('header-cell-')
    || ['body', 'header', 'item', 'bottom', 'body-cell-actions'].includes(s)));

const titledHeaders = computed(() => props.fields
  .filter(f => f.labelTitle && !(('header-cell-' + f.name) in slots))
  .map(f => ['header-cell-' + f.name, f]));

// noinspection NestedConditionalExpressionJS
const options = computed((): ReportOptions | null =>
  pagination.value ? {
    query:   searchString.value || '',
    filter:  filtersForInput.value,
    page:    pagination.value!.page!,
    perPage: pagination.value!.rowsPerPage!,
    sort:    pagination.value!.sortBy && visibleFieldsNames.value.includes(pagination.value!.sortBy)
      ? [{
        field: pagination.value!.sortBy,
        order: pagination.value!.descending ? SortOrderEnum.DESC : SortOrderEnum.ASC,
      }]
      : [],
    columns: visibleFieldsNames.value,
  } : null);

loadOptions();

const loadingFields = ref<string[]>([]);

watch(visibleFieldsNames, (newFields, oldFields) => {
  if (!props.noLoadingColumns) {
    loadingFields.value = difference(newFields, oldFields);
  }
});

watch(() => props.rows, () => {
  loadingFields.value = [];
});

function clearSelectedItems(): void {
  selectedItems.value = [];
}

watch(() => props.total, total => {
  pagination.value!.rowsNumber = total;
});

watch(options, function optionsChanged(): void {
  refresh();
  saveOptions();
});

watch([visibleFieldsNames, scroll], saveOptions, { deep: true });

function saveOptions(): void {
  if (props.keepPageInUrl) {
    const page = pagination.value.page > 1 ? pagination.value.page : undefined;
    router.replace({ query: { page } });
  }

  if (!storedOptions) {
    return;
  }

  storedOptions.value = {
    searchString: props.keepSearchString ? searchString.value : null,
    filters:      filters.value,
    headers:      visibleFieldsNames.value,
    scroll:       scroll.value,
    itemsPerPage: pagination.value!.rowsPerPage!,
    sortBy:       pagination.value!.sortBy!,
    sortDesc:     pagination.value!.descending!,
    showDeleted:  showDeleted.value,
  };
}

function loadOptions(): void {
  pagination.value ??= {};

  pagination.value.page = Math.max(Number.parseInt(route.query.page) || 1, 1);

  const options = storedOptions?.value;
  if (!options) {
    return;
  }

  searchString.value = options.searchString;
  filters.value = restoreFilters(options.filters);
  visibleFieldsNames.value = options.headers
    // Отбрасываем "неактуальные" поля - поля, которые сохранились у пользователя в настройках,
    // но позже были убраны в приложении.
    .filter(h => props.fields.find(f => f.name === h));

  Object.assign(pagination.value, {
    rowsPerPage: options.itemsPerPage,
    sortBy:      Array.isArray(options.sortBy) ? options.sortBy[0] : options.sortBy,
    descending:  Array.isArray(options.sortDesc) ? options.sortDesc[0] : options.sortDesc,
  });

  scrollAfterFirstLoad.value = options.scroll;

  showDeleted.value = options.showDeleted ?? false;
}

watchOnce(() => props.rows, () => {
  nextTick(() => {
    tableScrollEl.value!.scroll({ top: scrollAfterFirstLoad.value });
  });
});

function refresh(): void {
  clearSelectedItems();
  emit('refresh', options.value);
}

function cellClass(item: TItem & { isSummary?: boolean }): string {
  return !item.isSummary && props.rowIsDeleted?.(item) ? 'deleted-row-cell' : '';
}

const tableClasses = computed(() => ({
  'sticky-header': props.stickyHeader,
  'shrink': !props.noShrink,
}));

const justSearched = ref(false);

watch(searchString, () => {
  justSearched.value = true;
});

watch(() => props.rows, items => {
  if (items.length === 1 && justSearched.value && searchString.value) {
    emit('single-item-found', items[0], searchString.value);
  }
  justSearched.value = false;
});

watch(() => props.rows, rows => {
  // Если текущая страница > 1 и на ней нет данных,
  if (rows.length === 0 && pagination.value?.page > 1) {
    // возвращаемся на первую.
    pagination.value.page = 1;
  }
});

const SummaryRow: Component<{
  row: TItem;
  cols: TableColumn[];
}> = ({ row, cols }) => h(QTr, { class: 'summary-row' }, () => [
  slots['batch-actions'] ? h(QTd) : null,
  cols.map(col => h(QTd, { props: { row: { ...row, isSummary: true }, col } }, () => {
    if (!row || !col.summaryField) {
      return null;
    }
    return getRowField(row, col.summaryField === true ? col.field : col.summaryField);
  }))
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRowField(row: TItem, field: keyof TItem | ((row: TItem) => any)) {
  return (typeof field === 'function') ? field(row) : row[field];
}

defineExpose({
  resetSearch: () => {
    searchString.value = '';
  },
  clearSelectedItems,
});

</script>

<style lang="scss">

@import "quasar/src/css/variables";
@import "@/css/mixins";

@include dark-mode() using ($isDark) {
  .q-table .deleted-row-cell {
    color: if($isDark, $grey-9, $grey-4);
  }

  .sticky-header {
    .q-table thead tr th {
      background: if($isDark, $dark, white);
    }
  }
}

.q-table__top {
  padding-left: 0;
  padding-bottom: 0;
}

.sticky-header {
  .q-table thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .q-table__middle {
    flex-grow: 0;
  }
}

.shrink .q-table__middle .q-table {
  width: auto;
}

.summary-row {
  background-color: $grey-4;
  font-weight: bold;
}

</style>

<i18n lang="yaml">
ru:
  Add Filter: Добавить фильтр

  Confirm action for {n} rows: >
    Подтвердите действие для {n} строк
    | Подтвердите действие для {n} строки
    | Подтвердите действие для {n} строк
    | Подтвердите действие для {n} строк

  Show: Показать
  All: Все
  All but deleted: Все, кроме удаленных

en:
  Add Filter: Add Filter
  Confirm action for {n} rows: >
    Confirm action for one row
    | Confirm action for {n} rows

  Show: Показать
  All: Все
  All but deleted: Все, кроме удаленных
</i18n>
