<template>
  <ShipmentsList
    :extra-fields="tableFields as TableColumn<SupplierReturn>[]"
    :extra-filters="availableFilters"
    :fixed-filters="fixedFilters"
    storage-prefix="supplierReturnsList.report"
    mobile-storage-prefix="supplierReturnsList.mobileReport"
  >
    <template #import-export="{ options, onSuccess }">
      <SupplierReturnsExcelImportExport
        :options="options"
        @success="onSuccess"
      />
    </template>
    <template #validation-message="{ ids }">
      {{ t('Supplier Returns {ids} cannot be deleted', { ids: ids.join(', ') }, ids.length) }}
    </template>
    <template #confirmation-message="{ notDeletedItems }">
      {{ t('Delete {n} Supplier Returns?', { id: notDeletedItems[0].externalId ?? notDeletedItems[0].id }, notDeletedItems.length) }}
    </template>
  </ShipmentsList>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import getSuppliersForSelect from '@/graphql/shorthands/getSuppliersForSelect';
import type { ReportFilterInput, SupplierReturn, Supplier } from '@/graphql/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ShipmentsList from '@/views/Shipping/Shipments/ShipmentsList.vue';
import type { ReportFilter, TableColumn } from '@/types/reports';
import * as reports from '@/helpers/reports';
import SupplierReturnsExcelImportExport from '@/views/Shipping/SupplierReturns/SupplierReturnsExcelImportExport.vue';

const { t } = useI18n();

useBreadcrumbs(t('Supplier Returns'));

const tableFields: TableColumn<SupplierReturn>[] = [
  {
    label:  t('Supplier'),
    name:   'supplier',
    field:  'counterparty',
    format: (supplier?: Supplier) => supplier?.name ?? '',
    align:  'left',
  },
  {
    label: t('Supply'),
    name:  'supplyId',
    field: sr => sr.supplyNumber || sr.supply?.externalId,
    align: 'left',
  },
  {
    label:    t('Return Number'),
    name:     'externalId',
    field:    'externalId',
    sortable: true,
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:                 'supplier',
    label:                 t('Supplier'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('in', getSuppliersForSelect),
    ],
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => [
  {
    field:    'type',
    operator: '=',
    value:    JSON.stringify('SupplierReturn' as SupplierReturn['__typename']),
  },
]);

</script>

<i18n lang="yaml">
ru:
  Supplier Returns {ids} cannot be deleted: >
    Нельзя удалить возвраты поставщикам {ids}
    | Нельзя удалить возврат поставщику {ids}
    | Нельзя удалить возвраты поставщикам {ids}
    | Нельзя удалить возвраты поставщикам {ids},

  Delete {n} Supplier Returns?: >
    Удалить {n} возвратов поставщикам?
    | Удалить возврат поставщику {id}?
    | Удалить {n} возврата поставщикам?
    | Удалить {n} возвратов поставщикам?

en:
  Supplier Returns {ids} cannot be deleted: >
    Supplier Return {ids} cannot be deleted
    | Supplier Returns {ids} cannot be deleted

  Delete {n} Supplier Returns?: >
    Delete Supplier Return {id}?
    | Delete {n} Supplier Returns?
</i18n>
