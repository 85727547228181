<template>
  <ProductSlide
    :product-pack="item.storable"
    :actual-amount="displayAmount"
    :total-amount="totalProductAmount"
    :show-total-amount="totalProductAmount !== amountInMinUnits"
    :expected-amount="expectedAmount"
    :expected-amount-loading="expectedAmountLoading"
    :amount-badge-color="amountBadgeColor"
    @amount-click="handleAmountClick"
    @delete="emit('delete')"
  >
    <template #top="{ product }">
      <div
        v-clamp="product.accountingModel.byBatch ? 2 : 3"
        class="text-h5 cursor-pointer"
        @click="editingProduct = product"
      >
        {{ product.name }}
      </div>
      <ProductSlideBatch
        v-if="product.accountingModel.byBatch"
        :batch-name="formatBatchName(item.batch, product.accountingModel, t)"
        :accounting-model="product.accountingModel"
        class="cursor-pointer"
        @click="handleBatchClick"
      />

      <EditBatchData
        v-model:is-open="editingBatchData"
        :batch="item.batch"
        :product="item.storable.product"
        @save="handleBatchSave"
      />

      <ProductEditDialog
        v-if="editingProduct"
        :id="editingProduct.id"
        @cancel="editingProduct = null"
      />
    </template>
    <template
      v-if="allProductStocks.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="allProductStocks.length"
        :active="allProductStocks.indexOf(item)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">

import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import EditBatchData from '@/components/Mobile/EditBatchData.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import ProductSlideBatch from '@/components/Mobile/ProductSlideBatch.vue';
import vClamp from '@/directives/clamp';
import type { InventoryBatchDataInput, Product } from '@/graphql/types';
import formatBatchName from '@/helpers/formatBatchName';
import type { ActualInventoryStock } from '@/types/inventory';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import { roundTo } from 'round-to';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  item: ActualInventoryStock;
  actualStocks: ActualInventoryStock[];
  displayAmount: number | string;
  expectedAmount: number;
  expectedAmountLoading: boolean;
}>();

const emit = defineEmits<{
  'update:slide': [slide: number];
  'delete': [];
}>();

const editingBatchData = ref(false);

function handleBatchClick() {
  if (props.item.storable.product.accountingModel.byBatch) {
    editingBatchData.value = true;
  }
}

function handleBatchSave(batchData: InventoryBatchDataInput) {
  Object.assign(props.item.batch, batchData);
  editingBatchData.value = false;
}

const amountBadgeColor = computed(() => totalProductAmount.value === props.expectedAmount
  ? 'green-3'
  : 'yellow-3');

const allProductStocks = computed(() => props.actualStocks
  .filter(s => s.storable.product.id === props.item.storable.product.id));

const amountInMinUnits = computed(() => roundTo(
  (props.item.amount * props.item.storable.quantityInMinMeasurementUnits), 3
));

const totalProductAmount = computed(() => roundTo(allProductStocks.value
  .reduce((a, s) => a + s.amount * s.storable.quantityInMinMeasurementUnits, 0), 3));

function handleAmountClick() {
  const index = (allProductStocks.value.indexOf(props.item) + 1) % allProductStocks.value.length;

  emit('update:slide', props.actualStocks.indexOf(allProductStocks.value[index]));
}

const editingProduct = ref<Product | null>(null);

</script>
