<template>
  <SuppliesList
    :supply-route="(ret: SupplierDelivery) => ({ name: ROUTES.SUPPLIES_EDIT, params: { id: ret.id } })"
    :extra-fields="tableFields as TableColumn<Supply>[]"
    :extra-filters="availableFilters"
    :fixed-filters="fixedFilters"
    storage-prefix="suppliesList.report"
    mobile-storage-prefix="suppliesList.mobileReport"
  >
    <template #import-export="{ options, onSuccess }">
      <SuppierDeliveriesExcelImportExport
        :options="options"
        @success="onSuccess"
      />
    </template>
  </SuppliesList>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import getSuppliersForSelect from '@/graphql/shorthands/getSuppliersForSelect';
import type {
  ReportFilterInput,
  Supplier,
  SupplierDelivery,
  Supply,
} from '@/graphql/types';
import * as reports from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { ReportFilter, TableColumn } from '@/types/reports';
import SuppierDeliveriesExcelImportExport
  from '@/views/Arrival/Supply/SuppierDeliveriesExcelImportExport.vue';
import SuppliesList from '@/views/Arrival/Supply/SuppliesList.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

useBreadcrumbs(t('Supplies'));

const tableFields: TableColumn<SupplierDelivery>[] = [
  {
    label:  t('Supplier'),
    name:   'supplier',
    field:  'counterparty',
    format: (supplier?: Supplier) => supplier?.name ?? '',
    align:  'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:                 'supplier',
    label:                 t('Supplier'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('in', getSuppliersForSelect, t('reportFilterOperator.in')),
    ],
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'type',
  operator: '=',
  value:    JSON.stringify('SupplierDelivery' as SupplierDelivery['__typename']),
}]);

</script>
