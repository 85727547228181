<template>
  <ProductsListMobile
    v-if="preferences.terminalMode"
    :available-filters="availableFilters"
  />
  <FullHeightPage v-else>
    <GraphQLQueryTable
      ref="report"
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :row-is-deleted="entityIsDeleted"
      storage-prefix="productsList.report"
      keep-page-in-url
      :deletion-filter="deletionFilterForSoftDeletable"
      sticky-header
      class="col"
      @single-item-found="handleSingleProductFound"
    >
      <template #after-search-string="{ options }">
        <ProductsExcelImportExport
          :options="options"
          @success="report.refresh()"
        />
      </template>

      <template #batch-actions="{ rows, refresh, clear }">
        <SimpleTableBatchActions :rows-count="rows.length">
          <DeletionWithValidation
            v-if="rows.some(item => item.deletedAt === null)"
            :items="rows"
            :delete="deleteProduct"
            :validate="validateProduct"
            @deleted="refresh()"
          >
            <template #validation-error-hasStocks="{ items }">
              <BaseAlert type="error">
                {{
                  t(
                    'Products {skus} with stocks cannot be deleted',
                    {skus: items.map(p => p.sku).join(', ')},
                    items.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #validation-error-hasNotCompletedShipments="{ items }">
              <BaseAlert type="error">
                {{
                  t(
                    'Products {skus} with not completed orders cannot be deleted',
                    {skus: items.map(p => p.sku).join(', ')},
                    items.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #validation-error-hasNotCompletedSupplies="{ items }">
              <BaseAlert type="error">
                {{
                  t(
                    'Products {skus} with not completed supplies cannot be deleted',
                    {skus: items.map(p => p.sku).join(', ')},
                    items.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #confirmation>
              <QCardSection class="text-body1">
                {{ getConfirmMessageForDeletion(rows) }}
              </QCardSection>
            </template>
          </DeletionWithValidation>

          <RestoreItemsButton
            :items="rows"
            :restore="restoreProduct"
            @restored="refresh()"
          >
            <template #confirmation>
              <div class="text-h6">
                {{ getConfirmMessageForRestoring(rows) }}
              </div>
            </template>
          </RestoreItemsButton>

          <ProductsPrintDialog
            :products="rows.filter(item => item.deletedAt === null)"
            @done="clear()"
          />
        </SimpleTableBatchActions>
      </template>

      <template #body-cell-sku="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: String(column.row.id) } }"
        />
      </template>

      <template #body-cell-name="scope">
        <QTd
          :props="scope"
          style="white-space: normal;"
        >
          <div v-clamp="3">
            {{ scope.row.name }}
          </div>
        </QTd>
      </template>

      <template #body-cell-category="column">
        <QTd :props="column">
          {{ column.row.category?.name }}
        </QTd>
      </template>

      <template #body-cell-deletedAt="column">
        <QTd
          :props="column"
          :title="formatDate(column.row.deletedAt)"
        >
          {{ column.row.deletedAt ? t('No') : '' }}
        </QTd>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import type BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import vClamp from '@/directives/clamp';
import getProductCategoriesForSelect from '@/graphql/shorthands/getProductCategoriesForSelect';
import getSuppliersForSelect from '@/graphql/shorthands/getSuppliersForSelect';
import type {
  AccountingModel,
  MeasurementUnit,
  MutationDeleteProductArgs,
  MutationRestoreProductArgs,
  Product,
  ProductCategory,
  ProductPack,
  ProductsReportRow,
  QueryValidateDeletionProductArgs,
  Supplier,
  ValidateDeletionProductResult,
} from '@/graphql/types';
import * as reports from '@/helpers/reports';
import {
  createCustom,
  createDatesRangeOperator,
  createPrimitive,
  deletionFilterForSoftDeletable,
  entityIsDeleted,
} from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type {
  FilterOperator,
  FilterOperatorValue,
  ReportFilter,
  TableColumn,
} from '@/types/reports';
import DeletionWithValidation from '@/views/DeletionWithValidation.vue';
import ProductsExcelImportExport from '@/views/Products/ProductsExcelImportExport.vue';
import ProductsListMobile from '@/views/Products/ProductsListMobile.vue';
import ProductsListStockFilter from '@/views/Products/ProductsListStockFilter.vue';
import ProductsPrintDialog from '@/views/Products/ProductsPrintDialog.vue';
import RestoreItemsButton from '@/views/RestoreItemsButton.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import { gql, useClientHandle } from '@urql/vue';
import { markRaw, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const { dateFormatter, formatDate, formatWeight } = useLocalizedFormatters();

useBreadcrumbs(t('Products'));

const { client: urql } = useClientHandle();

const report = ref<InstanceType<typeof BaseTable>>(null);

const tableFields: TableColumn<ProductsReportRow>[] = [
  {
    label:    t('SKU'),
    name:     'sku',
    field:    'sku',
    align:    'left',
    sortable: true,
  },
  {
    label:    t('Name'),
    name:     'name',
    field:    'name',
    align:    'left',
    sortable: true,
  },
  {
    label:  t('Product Category'),
    name:   'category',
    field:  'category',
    format: (category?: ProductCategory) => category?.name ?? '',
    align:  'left',
  },
  {
    label:  t('Weight'),
    name:   'weight',
    field:  'mostBasicProductPack',
    format: (pack?: ProductPack) => formatWeight(pack?.weight),
  },
  {
    label:  t('Base Unit'),
    name:   'baseUnit',
    field:  'mostBasicProductPack',
    format: (pack?: ProductPack) => pack?.measurementUnit.name ?? '',
    align:  'left',
  },
  {
    label:  t('Supplier'),
    name:   'primarySupplier',
    field:  'primarySupplier',
    format: (supplier?: Supplier) => supplier?.name ?? '',
    align:  'left',
  },
  {
    label:  t('Basic Product Pack barcode'),
    name:   'basePackBarcode',
    field:  'mostBasicProductPack',
    format: (pack?: ProductPack) => (pack?.lastScannedBarcode
      ?? pack?.barcodes.find(b => !b.group))
      ?.barcode ?? '',
    align:  'left',
    // Требуется для групповой печати
    mandatory: true
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:    dateFormatter(FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:  t('Accounting model'),
    name:   'accountingModel',
    field:  'accountingModel',
    format: (model?: AccountingModel) => model?.name ?? '',
    align:  'left',
  },
  {
    label:      t('Sel. stock'),
    labelTitle: t('Stock in selection Cells Area'),
    name:       'stockInSelectionArea',
    field:      'stockInSelectionArea',
  },
  {
    label:      t('Stor. stock'),
    labelTitle: t('Stock in storage Cells Area'),
    name:       'stockInStorageArea',
    field:      'stockInStorageArea',
  },
  {
    label:      t('Ext. stock'),
    labelTitle: t('Stock in external Cells Area'),
    name:       'stockInExternalArea',
    field:      'stockInExternalArea',
  },
  {
    label:      t('Defect stock'),
    labelTitle: t('Stock in defect Cells Area'),
    name:       'stockInDefectArea',
    field:      'stockInDefectArea',
  },
  {
    label:      t('Reserve'),
    labelTitle: t('Reserved by orders'),
    name:       'reserve',
    field:      'reserve',
  },
  {
    label:  t('Product flow'),
    name:   'productFlow',
    field:  'productFlow',
    format: flow => flow?.toFixed(2) ?? '',
  },
  {
    label: t('Amount'),
    name:  'amountInMinMeasurementUnits',
    field: 'amountInMinMeasurementUnits',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:                 'createdAt',
    label:                 t('Created at'),
    hideLabelWhenSelected: true,
    operators:             [
      createDatesRangeOperator(),
    ],
  },
  {
    field:                 'category',
    label:                 t('Product Category'),
    hideLabelWhenSelected: true,
    operators:             [
      createCategoryListFilterOperator('in'),
    ],
  },
  {
    field:     'weight',
    label:     t('Weight'),
    operators: [
      createPrimitive('>', 'string', {
        valueSuffix:            t('units.kg'),
        inputType:              'number',
        preferPhysicalKeyboard: true,
      }),
      createPrimitive('<', 'string', {
        valueSuffix:            t('units.kg'),
        inputType:              'number',
        preferPhysicalKeyboard: true,
      }),
    ],
  },
  {
    field:                 'primarySupplier',
    label:                 t('Supplier'),
    hideLabelWhenSelected: true,
    operators:             [
      createSuppliersListFilterOperator('in'),
    ],
  },
  {
    field:                 'accountingModel',
    label:                 t('Accounting model'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList(
        'in',
        () => urql.query<{ models: AccountingModel[] }>(
          gql`
            query GetAccountingModelsForProductsReportFilter {
              models: accountingModels { id name }
            }
          `,
          {},
        ).then(({ data }) => data!.models.map(model => ({
          value: model.id,
          label: model.name,
        }))),
      )
    ],
  },
  {
    field:     'amountInMinMeasurementUnits',
    label:     t('Amount'),
    operators: [
      ...(['>', '=', '<', '!='] as const).map(op => createCustom(
        op,
        op,
        markRaw(ProductsListStockFilter),
        { keepFilterValue: true, operatorSlot: true }
      )),
    ],
  },
  {
    field:                 'units',
    label:                 t('Measurement Units'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList(
        'in',
        () => urql.query<{ units: MeasurementUnit[] }>(
          gql`
            query GetAccountingModelsForProductsReportFilter {
              units: measurementUnits { id name shortName }
            }
          `,
          {},
        ).then(({ data }) => data!.units.map(unit => ({
          value: unit.id,
          label: unit.name,
        }))),
      ),
    ]
  }
];

function createCategoryListFilterOperator(operator: FilterOperatorValue): FilterOperator {
  return reports.createList(operator, getProductCategoriesForSelect, t(`reportFilterOperator.${operator}`), {
    nullOption: () => ({
      label: '[' + t('No Category') + ']',
      value: null,
    }),
  });
}

// noinspection FunctionNamingConventionJS
function createSuppliersListFilterOperator(operator: FilterOperatorValue): FilterOperator {
  return reports.createList(
    operator,
    getSuppliersForSelect,
    t(`reportFilterOperator.${operator}`),
    {
      nullOption: () => ({
        label: '[' + t('No Supplier') + ']',
        value: null,
      }),
    },
  );
}

const query = gql`
  query GetProducts(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: productsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        id
        createdAt
        sku
        name
        category { id name }
        primarySupplier { id name }
        productPacks {
          id
          lastScannedBarcode { barcode }
          measurementUnit { id name isFractional class }
          barcodes { barcode group }
          product { id sku }
        }
        mostBasicProductPack {
          id
          weight
          measurementUnit { id name isFractional }
          lastScannedBarcode { barcode }
          barcodes { barcode }
          product { id sku }
        }
        accountingModel { id name }
        stockInSelectionArea
        stockInStorageArea
        stockInExternalArea
        stockInDefectArea
        reserve
        productFlow
        amountInMinMeasurementUnits
        deletedAt
      }
      total
    }
  }
`;

function getConfirmMessageForDeletion(items: Product[]): string {
  const notDeletedItems = items.filter(item => item.deletedAt === null);
  return t('Delete {n} products?', { sku: notDeletedItems[0].sku }, notDeletedItems.length);
}

async function deleteProduct(product: Product): Promise<void> {
  await urql.mutation<unknown, MutationDeleteProductArgs>(
    gql`mutation DeleteProduct($id: ID!) { deleteProduct(id: $id) }`,
    { id: product.id }
  );
}

function getConfirmMessageForRestoring(items: Product[]): string {
  const deletedItems = items.filter(item => item.deletedAt !== null);
  return t('Restore {n} products?', { sku: deletedItems[0].sku }, deletedItems.length);
}

async function restoreProduct(product: Product): Promise<void> {
  await urql.mutation<unknown, MutationRestoreProductArgs>(
    gql`mutation RestoreProduct($id: ID!) { restoreProduct(id: $id) { id } }`,
    { id: product.id }
  );
}

async function validateProduct(product: Product): Promise<true | string> {
  if (product.deletedAt) {
    return true;
  }

  const { data } = await urql.query<
    { validationResult: ValidateDeletionProductResult },
    QueryValidateDeletionProductArgs
  >(
    gql`
      query ValidateProduct($id: ID!) {
        validationResult: validateDeletionProduct(id: $id) {
          hasNotCompletedShipments
          hasNotCompletedSupplies
          hasStocks
        }
      }
    `,
    { id: product.id },
  );

  const { validationResult } = data!;

  if (validationResult.hasNotCompletedSupplies) {
    return 'hasNotCompletedSupplies';
  }
  if (validationResult.hasNotCompletedShipments) {
    return 'hasNotCompletedShipments';
  }
  if (validationResult.hasStocks) {
    return 'hasStocks';
  }

  return true;
}

const router = useRouter();

const preferences = useLocalPreferences();

function handleSingleProductFound(product: Product, searchString: string) {
  const searchStringIsProductBarcode = product.productPacks
    .some(pp => pp.barcodes.some(pb => pb.barcode === searchString));

  if (searchStringIsProductBarcode) {
    router.push({ name: ROUTES.PRODUCTS_EDIT, params: { id: product.id } });
  }
}

</script>

<i18n lang="yaml">
ru:
  Product Category: Группа товаров
  Base Unit: Базовая единица упаковки
  Active: Активность
  Basic Product Pack barcode: Штрихкод базовой упаковки
  Sel. stock: Ост. отбор
  Stock in selection Cells Area: Остаток в зоне отбора
  Stor. stock: Ост. хран.
  Stock in storage Cells Area: Остаток в зоне хранения
  Ext. stock: Ост. внеш.
  Stock in external Cells Area: Остаток на внешнем складе
  Defect stock: Ост. брак.
  Stock in defect Cells Area: Остаток в зоне брака
  Reserve: Резерв
  Reserved by orders: Зарезервировано заказами
  Product flow: Уходимость
  No Category: Нет группы
  No Supplier: Нет поставщика
  Products {skus} with stocks cannot be deleted: >
    Нельзя удалить товары {skus}, для которых есть остатки
    | Нельзя удалить товар {skus}, для которого есть остатки
    | Нельзя удалить товары {skus}, для которых есть остатки
    | Нельзя удалить товары {skus}, для которых есть остатки
  Products {skus} with not completed orders cannot be deleted: >
    Нельзя удалить товары {skus}, для которых есть незавершённые заказы
    | Нельзя удалить товар {skus}, для которого есть незавершённые заказы
    | Нельзя удалить товары {skus}, для которых есть незавершённые заказы
    | Нельзя удалить товары {skus}, для которых есть незавершённые заказы
  Products {skus} with not completed supplies cannot be deleted: >
    Нельзя удалить товары {skus}, для которых есть незавершенные поступления
    | Нельзя удалить товар {skus}, для которого есть незавершенные поступления
    | Нельзя удалить товары {skus}, для которых есть незавершенные поступления
    | Нельзя удалить товары {skus}, для которых есть незавершенные поступления
  Delete {n} products?: >
    Удалить {n} товаров?
    | Удалить товар {sku}?
    | Удалить {n} товара?
    | Удалить {n} товаров?
  Restore {n} products?: >
    Восстановить {n} товаров?
    | Восстановить товар {sku}?
    | Восстановить {n} товара?
    | Восстановить {n} товаров?
  Measurement Units: Единицы упаковки
en:
  Product Category: Product Category
  Base Unit: Base Unit
  Active: Active
  Basic Product Pack barcode: Basic Product Pack barcode
  Sel. stock: Sel. stock
  Stock in selection Cells Area: Stock in selection Cells Area
  Stor. stock: Stor. stock
  Stock in storage Cells Area: Stock in storage Cells Area
  Ext. stock: Ext. stock
  Stock in external Cells Area: Stock in external Cells Area
  Defect stock: Defect stock
  Stock in defect Cells Area: Stock in defect Cells Area
  Reserve: Reserve
  Reserved by orders: Reserved by orders
  Product flow: Product flow
  No Category: No Category
  No Supplier: No Supplier
  Delete {n} products?: >
    Delete product {sku}?
    | Delete {n} products?
  Products {skus} with stocks cannot be deleted: >
    Product {skus} with stocks cannot be deleted
    | Products {skus} with stocks cannot be deleted
  Products {skus} with not completed orders cannot be deleted: >
    Product {skus} with not completed orders cannot be deleted
    | Products {skus} with not completed orders cannot be deleted
  Products {skus} with not completed supplies cannot be deleted: >
    Product {skus} with not completed supplies cannot be deleted
    | Products {skus} with not completed supplies cannot be deleted
  Restore {n} products?: >
    Restore product {sku}?
    | Restore {n} products?
  Measurement Units: Measurement Units
</i18n>
