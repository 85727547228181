ru:
  By Task: По заданию
  Cancel Transfer?: Отменить перемещение?
  Free: Свободное
  Storage To Hint: Куда перемещать товары
en:
  By Task: By Task
  Cancel Transfer?: Cancel Transfer?
  Free: Free
  Storage To Hint: To where to transfer products
