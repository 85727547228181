<template>
  <!--
  width: 100% нужен временно, пока слайд имеет класс inline-block
  и может использоваться как в ScrollCarousel, так и в SwiperCarousel.
  После полного перевода на SwiperCarousel
  убрать width: 100% здесь и inline-block у ProductSlide
  -->
  <ProductSlide
    :product-pack="item.storable.productPack"
    :actual-amount="displayAmount"
    :total-amount="totalProductAmount"
    :show-total-amount="totalProductAmount !== amountInMinUnits"
    :expected-amount="expectedAmount"
    :expected-amount-loading="!store.transfer?.task && store.stocksInStorageFromLoading"
    style="width: 100%"
    @amount-click="handleAmountClick"
    @delete="emit('delete')"
  >
    <template #top="{ product }">
      <div
        v-clamp="batch.product.accountingModel.byBatch ? 2 : 3"
        class="text-h5 cursor-pointer"
        @click="editingProduct = batch.product"
      >
        {{ product.name }}
      </div>
      <ProductSlideBatch
        v-if="batch.product.accountingModel.byBatch"
        :batch-name="formatBatchName(batch, batch.product.accountingModel, t)"
        :accounting-model="batch.product.accountingModel"
        class="cursor-pointer"
        @click="editingBatchData = true"
      />

      <EditStorageUnitBatch
        v-if="batch.product.accountingModel?.batchDisplayFormat"
        v-model:is-open="editingBatchData"
        :storage-unit="item.storable"
        @saved="emit('storage-unit-saved', $event)"
      />

      <ProductEditDialog
        v-if="editingProduct"
        :id="editingProduct.id"
        @cancel="editingProduct = null"
      />
    </template>
    <template
      v-if="allProductMovements.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="allProductMovements.length"
        :active="allProductMovements.indexOf(item)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">

import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import ProductSlideBatch from '@/components/Mobile/ProductSlideBatch.vue';
import vClamp from '@/directives/clamp';
import type { Movement, Product, StorageUnit } from '@/graphql/types';
import formatBatchName from '@/helpers/formatBatchName';
import useTransferProcessStore from '@/stores/transferProcess';
import EditStorageUnitBatch from '@/views/Mobile/Arrival/EditStorageUnitBatch.vue';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import { roundTo } from 'round-to';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useTransferProcessStore();

const props = defineProps<{
  item: Movement;
  displayAmount: number | string;
}>();

const emit = defineEmits<{
  'delete': [];
  'storage-unit-saved': [storageUnit: StorageUnit];
  'product-edit-opened': [opened: boolean];
  'update:slide': [slide: number];
}>();

const expectedAmount = computed(() => {
  const packsWithAmounts = store.transfer?.task
    ? store.transfer.items.map(item => ({ pack: item.storageUnit.productPack, amount: item.plannedAmount }))
    : store.stocksInStorageFrom.map(stock => ({ pack: stock.storageUnit.productPack, amount: stock.amount }));
  return packsWithAmounts
    .filter(({ pack }) => pack.product.id === props.item.storable.productPack.product.id)
    .reduce((sum, { pack, amount }) => sum + amount * pack.quantityInMinMeasurementUnits, 0);
});

const allProductMovements = computed(() => store.movements
  .filter(m => m.storable.productPack.product.id === props.item.storable.productPack.product.id));

const amountInMinUnits = computed(() => roundTo(
  (props.item.amount * props.item.storable.productPack.quantityInMinMeasurementUnits),3
));

const totalProductAmount = computed(() => roundTo(allProductMovements.value
  .reduce((a, s) => a + s.amount * s.storable.productPack.quantityInMinMeasurementUnits, 0), 3));

function handleAmountClick() {
  const index = (allProductMovements.value.indexOf(props.item) + 1) % allProductMovements.value.length;

  emit('update:slide', store.movements.indexOf(allProductMovements.value[index]));
}

const editingBatchData = ref(false);

const batch = computed(() => props.item.storable.batch);

const editingProduct = ref<Product | null>(null);

watch(() => editingProduct.value, () => emit('product-edit-opened', !!editingProduct.value));

</script>
